.loader-ios {
  margin: auto;
  -webkit-animation: iosIntro .6s;
  animation: iosIntro .6s;
}

.loader-ios svg {
  fill: #868686;
}

.loader-ios svg path:nth-of-type(1) {
  -webkit-animation: pulse 1s infinite linear;
  animation: pulse 1s infinite linear;
}

.loader-ios svg path:nth-of-type(2) {
  -webkit-animation: pulse 1s -.083s infinite linear;
  animation: pulse 1s -.083s infinite linear;
}

.loader-ios svg path:nth-of-type(3) {
  -webkit-animation: pulse 1s -.166s infinite linear;
  animation: pulse 1s -.166s infinite linear;
}

.loader-ios svg path:nth-of-type(4) {
  -webkit-animation: pulse 1s -.249s infinite linear;
  animation: pulse 1s -.249s infinite linear;
}

.loader-ios svg path:nth-of-type(5) {
  -webkit-animation: pulse 1s -.332s infinite linear;
  animation: pulse 1s -.332s infinite linear;
}

.loader-ios svg path:nth-of-type(6) {
  -webkit-animation: pulse 1s -.415s infinite linear;
  animation: pulse 1s -.415s infinite linear;
}

.loader-ios svg path:nth-of-type(7) {
  -webkit-animation: pulse 1s -.498s infinite linear;
  animation: pulse 1s -.498s infinite linear;
}

.loader-ios svg path:nth-of-type(8) {
  -webkit-animation: pulse 1s -.581s infinite linear;
  animation: pulse 1s -.581s infinite linear;
}

.loader-ios svg path:nth-of-type(9) {
  -webkit-animation: pulse 1s -.664s infinite linear;
  animation: pulse 1s -.664s infinite linear;
}

.loader-ios svg path:nth-of-type(10) {
  -webkit-animation: pulse 1s -.747s infinite linear;
  animation: pulse 1s -.747s infinite linear;
}

.loader-ios svg path:nth-of-type(11) {
  -webkit-animation: pulse 1s -.83s infinite linear;
  animation: pulse 1s -.83s infinite linear;
}

.loader-ios svg path:nth-of-type(12) {
  -webkit-animation: pulse 1s -.913s infinite linear;
  animation: pulse 1s -.913s infinite linear;
}

@-webkit-keyframes pulse {
  50% {
    fill: #868686;
  }

  to {
    fill: rgba(134, 134, 134, 0.4);
  }
}

@keyframes pulse {
  50% {
    fill: #868686;
  }

  to {
    fill: rgba(134, 134, 134, 0.4);
  }
}

@-webkit-keyframes iosIntro {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes iosIntro {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}